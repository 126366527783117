export interface ICreateTeam {
  id?: string
  name: string
  mainContact: string
  organizationID: string
}

export type Team = {
  id: string
  mainContact: string
  members: any[]
  name: string
  organizationID: string
}

export interface ICreateTeamMember {
  id?: string
  organizationID?: string
  primaryTeam: string
  name: string
  email: string
  phone?: string
  role: string
  assemblyLines: string[]
}

export enum Roles {
  guest = 'GUEST',
  InternalAdmin = 'INTERNAL_ADMIN',
  OrganizationAdmin = 'ORG_ADMIN',
  AssemblyLineAdmin = 'ASSEMBLY_LINE_ADMIN',
  AssemblyLineContributor = 'ASSEMBLY_LINE_CONTRIBUTOR',
}

export const RoleLabelMapping = {
  [Roles.InternalAdmin]: 'Internal Admin',
  [Roles.OrganizationAdmin]: 'Organization Admin',
  [Roles.AssemblyLineAdmin]: 'Assembly Line Admin',
  [Roles.AssemblyLineContributor]: 'Assembly Line Contributor',
  [Roles.guest]: 'Guest',
}

export type TeamMember = {
  id: string
  organizationID: string
  primaryTeam: string
  name: string
  email: string
  phone: string
  assemblyLines: string[]
  role: Roles
  oneTimeCode: string
  oneTimeCodeExpiration: number
  emailVerified: boolean
  lastSession: number
}
