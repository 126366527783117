import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { theme } from '@assembly/theme'
import { ThemeProvider } from '@mui/material/styles'
import { SideNavbar } from '@assembly/components'
import { RoutePaths } from '@assembly/constants'
import {
  AssemblyLine,
  AssemblyLines,
  AssemblyTemplate,
  AssemblyTemplates,
  Login,
  Organization,
  OrganizationManager,
  Resource,
  Resources,
  Team,
  TeamMember,
  Teams,
  Debug,
  Skills,
  SkillsAssessment,
  ResourcePayoutEntries,
  ResourcePayoutInvoices,
  TalentInvoices,
  Candidates,
  Jobs,
} from '@assembly/pages'
import { AppProvider, SnackBarProvider } from '@assembly/contexts'
import Capabilities from './pages/Capabilities'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import React from 'react'
import { checkSessionIsValid } from './api/auth'
import { User } from '@assembly/shared/types/user'
import { Roles } from './pages/Teams/types'
import moment from 'moment-timezone'

moment.tz.setDefault('America/Los_Angeles')

function App() {
  const didMountRef = React.useRef(false)
  const [showLogin, setShowLogin] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [userInfo, setUserInfo] = React.useState<User | null>(null)

  React.useEffect(() => {
    if (!didMountRef.current) {
      checkSessionIsValidAsync()
      didMountRef.current = true
    }
  }, [])

  const checkSessionIsValidAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await checkSessionIsValid()
      setUserInfo(data)
      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)

      if (error.response && error.response.status === 403) {
        setShowLogin(true)
      }
    }
  }

  const wrapInSideNavBar = (Component: any) => {
    return (
      <SideNavbar>
        <Component />
      </SideNavbar>
    )
  }

  const renderContent = () => {
    if (showLogin) {
      return <Login />
    }

    if (isLoading) {
      return <div></div>
    }

    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={RoutePaths.OrganizationManager} replace />}
          />
          <Route
            path={RoutePaths.OrganizationManager}
            element={wrapInSideNavBar(OrganizationManager)}
          />
          <Route
            path={RoutePaths.Organization}
            element={wrapInSideNavBar(Organization)}
          />
          <Route path={RoutePaths.Teams} element={wrapInSideNavBar(Teams)} />
          <Route path={RoutePaths.Team} element={wrapInSideNavBar(Team)} />
          <Route
            path={RoutePaths.TeamMember}
            element={wrapInSideNavBar(TeamMember)}
          />
          <Route
            path={RoutePaths.Resources}
            element={wrapInSideNavBar(Resources)}
          />
          <Route
            path={RoutePaths.Resource}
            element={wrapInSideNavBar(Resource)}
          />
          <Route
            path={RoutePaths.ResourcePayoutEntries}
            element={wrapInSideNavBar(ResourcePayoutEntries)}
          />
          <Route
            path={RoutePaths.ResourcePayoutInvoices}
            element={wrapInSideNavBar(ResourcePayoutInvoices)}
          />
          <Route
            path={RoutePaths.TalentInvoices}
            element={wrapInSideNavBar(TalentInvoices)}
          />
          <Route
            path={RoutePaths.Capabilities}
            element={wrapInSideNavBar(Capabilities)}
          />
          <Route path={RoutePaths.Skills} element={wrapInSideNavBar(Skills)} />
          <Route
            path={RoutePaths.AssemblyTemplates}
            element={wrapInSideNavBar(AssemblyTemplates)}
          />
          <Route
            path={RoutePaths.AssemblyTemplate}
            element={wrapInSideNavBar(AssemblyTemplate)}
          />
          <Route
            path={RoutePaths.AssemblyLines}
            element={wrapInSideNavBar(AssemblyLines)}
          />
          <Route
            path={RoutePaths.AssemblyLine}
            element={wrapInSideNavBar(AssemblyLine)}
          />
          <Route
            path={RoutePaths.SkillsAssessment}
            element={wrapInSideNavBar(SkillsAssessment)}
          />
          <Route
            path={RoutePaths.Candidates}
            element={wrapInSideNavBar(Candidates)}
          />
          <Route path={RoutePaths.Jobs} element={wrapInSideNavBar(Jobs)} />
          {userInfo?.role === Roles.InternalAdmin && (
            <Route path={RoutePaths.Debug} element={wrapInSideNavBar(Debug)} />
          )}
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <AppProvider user={userInfo as User}>
        <ThemeProvider theme={theme}>
          <SnackBarProvider>{renderContent()}</SnackBarProvider>
        </ThemeProvider>
      </AppProvider>
    </LocalizationProvider>
  )
}

export default App
